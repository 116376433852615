import React from "react";

import {
  StructureDefinition,
  BundleEntry,
  Resource,
  Patient,
  Encounter,
  Condition,
} from "@iguhealth/fhir-types";
import createHTTPClient from "@iguhealth/client/lib/http";
import { FHIRReferenceEditable, Button } from "@iguhealth/components";

import { RESET_BUNDLE } from "./bundle";

import "@iguhealth/components/dist/index.css";

const client = createHTTPClient({
  url: "https://open-api.iguhealth.app/w/system/api/v1/fhir/r4/",
});

function App() {
  const [sds, setSds] = React.useState<
    Record<string, StructureDefinition | undefined>
  >({});
  const [patientID, setPatientId] = React.useState<string>(
    "c8b9ec25-6a10-46b5-abbf-0f019516f0e4"
  );
  const [patient, setPatient] = React.useState<Patient>();
  const [encounters, setEncounters] = React.useState<Encounter[]>();
  const [conditions, setConditions] = React.useState<Condition[]>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<any>(null);

  React.useEffect(() => {
    client
      .batch(
        {},
        {
          resourceType: "Bundle",
          type: "batch",
          entry: [
            {
              request: {
                method: "GET",
                url: `StructureDefinition/Patient`,
              },
            },
            {
              request: {
                method: "GET",
                url: `StructureDefinition/Encounter`,
              },
            },
            {
              request: {
                method: "GET",
                url: `StructureDefinition/Condition`,
              },
            },
          ],
        }
      )
      .then((response) => {
        setSds(
          response.entry
            .map((entry: BundleEntry) => entry.resource)
            .filter(
              (v: Resource | undefined): v is StructureDefinition =>
                v !== undefined
            )
            .reduce(
              (
                acc: Record<string, StructureDefinition | undefined>,
                sd: StructureDefinition
              ) => ({
                ...acc,
                [sd.type]: sd,
              }),
              {}
            )
        );
      });
  }, [client]);

  const retrieveData = React.useCallback(() => {
    client
      .batch(
        {},
        {
          resourceType: "Bundle",
          type: "batch",
          entry: [
            {
              request: {
                method: "GET",
                url: `Patient/${patientID}`,
              },
            },
            {
              request: {
                method: "GET",
                url: `Condition?patient=${patientID}&_count=100`,
              },
            },
            {
              request: {
                method: "GET",
                url: `Encounter?patient=${patientID}&_count=100`,
              },
            },
          ],
        }
      )
      .then((response) => {
        setPatient(response.entry[0].resource);
        setConditions(
          response.entry[1]?.resource?.entry.map((e: BundleEntry) => e.resource)
        );
        setEncounters(
          response.entry[2]?.resource?.entry.map((e: BundleEntry) => e.resource)
        );
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [
    client,
    setPatient,
    setConditions,
    setEncounters,
    setLoading,
    setError,
    patientID,
  ]);

  React.useEffect(() => {
    retrieveData();
  }, [retrieveData]);

  return (
    <div className="flex justify-center items-center text-slate-700">
      <div className="container p-2">
        <h1 className="text-xl font-semibold mb-4 border-b py-2">
          Patient Reset App
        </h1>
        <div className="mb-2">
          <h2 className="text-lg font-semibold">Patient</h2>
          <div className="flex items-center justify-center space-x-2">
            <FHIRReferenceEditable
              client={client}
              resourceTypesAllowed={["Patient"]}
              value={{ reference: `Patient/${patientID}` }}
              onChange={(ref) => {
                if (ref.reference) {
                  setPatientId(ref.reference.split("/")[1]);
                }
              }}
            />
            <Button
              onClick={() => {
                if (encounters === undefined || encounters?.length === 0) {
                  alert(
                    "Patient must have an encounter existing already to reset."
                  );
                } else {
                  client
                    .transaction(
                      {},
                      {
                        resourceType: "Bundle",
                        type: "transaction",
                        entry: [
                          {
                            fullUrl: "#patient",
                            request: {
                              method: "GET",
                              url: `Patient/${patientID}`,
                            },
                            resource: patient,
                          },
                          {
                            fullUrl: "#encounter",
                            request: {
                              method: "GET",
                              url: `Encounter/${
                                encounters?.map((e) => e.id)[0]
                              }`,
                            },
                            resource: patient,
                          },
                          ...(conditions?.map((c) => ({
                            request: {
                              method: "DELETE",
                              url: `Condition/${c.id}`,
                            },
                            resource: c,
                          })) ?? []),
                          ...RESET_BUNDLE.entry
                            .filter(
                              (e) =>
                                e.request?.method === "POST" &&
                                e.resource?.resourceType === "Condition"
                            )
                            .map((e) => e.resource)
                            .map((c) => ({
                              request: {
                                method: "POST",
                                url: "Condition",
                              },
                              resource: {
                                ...c,
                                subject: { reference: "#patient" },
                                encounter: { reference: "#encounter" },
                              },
                            })),
                        ],
                      }
                    )
                    .then(() => {
                      retrieveData();
                      alert("Reset was Successful");
                    })
                    .catch((error) => {
                      setError(error);
                    });
                }
              }}
            >
              Reset
            </Button>
          </div>
        </div>
        <div className="flex space-x-1">
          <div className="flex flex-col flex-1">
            <h2 className="text-lg font-semibold">Encounters</h2>
            <div className="space-y-1 h-64 overflow-auto border p-2">
              {encounters?.map((e) => (
                <FHIRReferenceEditable
                  client={client}
                  resourceTypesAllowed={["Encounter"]}
                  value={{ reference: `Encounter/${e.id}` }}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-col flex-1">
            <h2 className="text-lg font-semibold">Conditions</h2>
            <div className="space-y-1 h-64 overflow-auto border p-2">
              {conditions?.map((c) => (
                <FHIRReferenceEditable
                  client={client}
                  resourceTypesAllowed={["Condition"]}
                  value={{ reference: `Condition/${c.id}` }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
