export const RESET_BUNDLE = {
  resourceType: "Bundle",
  id: "8ba03f7c-c9c2-42b9-a2ff-f0f85b07cb82",
  meta: {
    lastUpdated: "2023-01-12T13:41:17.092+00:00",
  },
  type: "transaction",
  total: 1,
  link: [
    {
      relation: "self",
      url: "https://api.logicahealth.org/FortechCDSHooksSandb/data/Condition?patient=Patient%2F2",
    },
  ],
  entry: [
    {
      fullUrl: "Encounter/e001",
      resource: {
        resourceType: "Encounter",
        id: "e001",
        identifier: [
          {
            use: "temp",
            value: "Encounter_Roel_20130128",
          },
        ],
        status: "in-progress",
        class: {
          system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
          code: "AMB",
          display: "ambulatory",
        },

        priority: {
          coding: [
            {
              system: "http://snomed.info/sct",
              code: "103391001",
              display: "Urgent",
            },
          ],
        },
        type: [
          {
            coding: [
              {
                system: "http://snomed.info/sct",
                code: "367336001",
                display: "Chemotherapy",
              },
            ],
          },
        ],
        subject: {
          reference: "Patient/14602",
          display: "Joan Doe",
        },
        participant: [
          {
            individual: {
              reference: "Practitioner/smart-Practitioner-71482713",
            },
          },
        ],
        length: {
          value: 56,
          unit: "minutes",
          system: "http://unitsofmeasure.org",
          code: "min",
        },
        diagnosis: [
          {
            condition: {
              display:
                "Complications from Roel's TPF chemotherapy on January 28th, 2013",
            },
            use: {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/diagnosis-role",
                  code: "AD",
                  display: "Admission diagnosis",
                },
              ],
            },
            rank: 2,
          },
          {
            condition: {
              display: "The patient is treated for a tumor",
            },
            use: {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/diagnosis-role",
                  code: "CC",
                  display: "Chief complaint",
                },
              ],
            },
            rank: 1,
          },
        ],
      },
      request: {
        method: "POST",
        url: "Encounter",
      },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "E11.9",
              display: "Type 2 diabetes mellitus without complications",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "C49.9",
              display:
                "Malignant neoplasm of connective and soft tissue, unspecified",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "R80.9",
              display: "Proteinuria, unspecified",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "R73.9",
              display: "Hyperglycemia, unspecified",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "H90.5",
              display: "Unspecified sensorineural hearing loss",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "E66.9",
              display: "Obesity, unspecified",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "I10",
              display: "Essential (primary) hypertension",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "R93.3",
              display:
                "Abnormal findings on diagnostic imaging of other parts of digestive tract",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "E11.319",
              display:
                "Type 2 diabetes mellitus with unspecified diabetic retinopathy without macular edema",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "Z92.29",
              display: "Personal history of other drug therapy",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "N20.0",
              display: "Calculus of kidney",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "N18.5",
              display: "Chronic kidney disease, stage 5",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "D64.9",
              display: "Anemia, unspecified",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "C73",
              display: "Malignant neoplasm of thyroid gland",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "I10",
              display: "Essential (primary) hypertension",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "E21.1",
              display:
                "Secondary hyperparathyroidism, not elsewhere classified",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "J45.909",
              display: "Unspecified asthma, uncomplicated",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "E78.5",
              display: "Hyperlipidemia, unspecified",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
    {
      resource: {
        resourceType: "Condition",
        meta: {
          extension: [
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-name",
              valueString: "Encounter Diagnosis Example",
            },
            {
              url: "http://hl7.org/fhir/StructureDefinition/instance-description",
              valueMarkdown:
                "This example of a US Core Condition Encounter Diagnosis Profile illustrates its use to capture information about a patient's encounter diagnosis.",
            },
          ],
          profile: [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition-encounter-diagnosis",
          ],
        },
        extension: [
          {
            url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
            valueDateTime: "2023-01-27",
          },
        ],
        clinicalStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-clinical",
              code: "resolved",
            },
          ],
        },
        verificationStatus: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/condition-ver-status",
              code: "confirmed",
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/condition-category",
                code: "encounter-diagnosis",
                display: "Encounter Diagnosis",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://hl7.org/fhir/sid/icd-10",
              code: "H54.3",
              display: "Unqualified visual loss, both eyes",
            },
          ],
          text: "",
        },
        subject: { reference: "Patient/14602", display: "Joan Doe" },
        encounter: { reference: "Encounter/e001" },
        onsetDateTime: "2023-01-27",
        abatementDateTime: "2023-03-01",
        recordedDate: "2023-02-01",
      },
      request: { method: "POST", url: "Condition" },
    },
  ],
};
